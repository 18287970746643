import { navigate } from 'gatsby';
import config from '../components/config';

export const isBrowser = () => typeof window !== 'undefined';

export const setAutoLogout = (milliseconds) => {
  setTimeout(() => {
    this.logoutHandler();
  }, milliseconds);
};

export const getUser = () => {
  if (typeof window !== 'undefined') {
    const token = localStorage.getItem('token');
    const expiryDate = localStorage.getItem('expiryDate');
    if (!token || !expiryDate) {
      return false;
    }
    if (new Date(expiryDate) <= new Date()) {
      logoutHandler();
      return false;
    }
    const remainingMilliseconds = new Date(expiryDate).getTime() - new Date().getTime();
    return true;
    setAutoLogout(remainingMilliseconds);
  }
};

export const logoutHandler = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('token');
    localStorage.removeItem('expiryDate');
    localStorage.removeItem('userId');
    navigate('/');
  }
};

export const loginHandler = (authData, cb) => {
  fetch(`${config.backend}/user/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: authData.email,
      password: authData.password,
    }),
  })
    .then((res) => {
      console.log(res);
      return res.json();
    })
    .then((resData) => {
      if (resData.errors) {
        cb(resData.message);
        throw new Error(
          'Validation failed',
        );
      }
      if (resData.errors) {
        cb('User Login Failed!');
        throw new Error('User login failed!');
      }
      localStorage.setItem('token', resData.token);
      localStorage.setItem('userId', resData.userId);
      localStorage.setItem('name', resData.name);
      localStorage.setItem('email', authData.email);
      localStorage.setItem('profile-url', resData.avatar ? `https://www.pixopixa.cyou${resData.avatar}` : 'https://www.pixopixa.cyou/images/profile-placeholder.png');
      const remainingMilliseconds = 365 * 24 * 60 * 60 * 1000;
      const expiryDate = new Date(
        new Date().getTime() + remainingMilliseconds,
      );
      localStorage.setItem('expiryDate', expiryDate.toISOString());
      setAutoLogout(remainingMilliseconds);
      navigate('/secure/profile');
    })
    .catch((err) => {
      console.log(err);
    });
};

export const signupHandler = (event, authData) => {
  event.preventDefault();
  // this.setState({ authLoading: true });
  const graphqlQuery = {
    query: `
        mutation {
          createUser(userInput: {email: "${
  authData.signupForm.email.value
}", name:"${authData.signupForm.name.value}", password:"${
  authData.signupForm.password.value
}"}) {
            _id
            email
          }
        }
      `,
  };
  fetch(`${config.backend}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(graphqlQuery),
  })
    .then((res) => res.json())
    .then((resData) => {
      if (resData.errors && resData.errors[0].status === 422) {
        throw new Error(
          "Validation failed. Make sure the email address isn't used yet!",
        );
      }
      if (resData.errors) {
        throw new Error('User creation failed!');
      }
      console.log(resData);
      // this.setState({ isAuth: false, authLoading: false });
      navigate('/');
    })
    .catch((err) => {
      console.log(err);
    });
};
