import React, { Component } from 'react';
import Layout from '../components/layout';
import { validateEmail } from '../util/validators.js';
import Input from '../components/input';
import config from '../components/config';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.state = {
      email: '',
      finished: false,
      emailError: false,
    };
  }

  inputChangeHandler(id, value) {
    this.setState({
      [id]: value,
    });
  }

  submitHandler(e) {
    e.preventDefault();
    if (!validateEmail(this.state.email)) {
      this.setState({
        emailError: true,
      });
      return;
    }
    fetch(`${config.backend}/user/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.state.email,
      }),
    });
    this.setState({
      finished: true,
    });
  }

  render() {
    return (
      <Layout>
        <form onSubmit={this.submitHandler}>
          <div style={{ padding: '10% 20%', backgroundColor: '#EAB700', border: '15px ridge #ce1417' }}>
            {/* <label htmlFor="email">Email</label> */}
            <h1>Please Enter Your Account Email</h1>
            <Input
              id="email"
              className="signup-input-element"
              type="text"
              name="email"
              label="Email"
              control="input"
              onChange={this.inputChangeHandler}
              error={this.state.emailError}
              errorMessage="Please Enter a Valid Email"
              onClick={() => this.setState({ emailError: false })}
            />
            <br />
            {/* <input name="email" onChange={e => {this.setState({email: e.target.value})}}/> */}
            <button type="submit" className="btn-sign-up-optin">Submit</button>
            {this.state.finished
              ? (
                <div>
                  <br />
                  <br />
                  <div style={{
                    backgroundColor: '#4BB543', color: '#ffffff', padding: '0.5rem', fontSize: '1rem', border: '1px solid #1e7011', fontWeight: '600', marginBottom: '1rem',
                  }}
                  >
                    Please check your inbox, your reset link will arrive shortly
                  </div>
                </div>
              )
              : null}
          </div>
        </form>
      </Layout>
    );
  }
}
export default ResetPassword;
