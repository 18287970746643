import React from 'react';

const input = (props) => (
  <div>
    {props.label && <label className="signup-label-element" htmlFor={props.id}>{props.label}</label>}
    {props.error ? (
      <span className="error">
        {' '}
        {props.errorMessage}
      </span>
    ) : null}
    {props.control === 'input' && (
      <input
        className="signup-input-element"
        type={props.type}
        id={props.id}
        required={props.required}
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(props.id, e.target.value, e.target.files)}
        onClick={props.onClick}
        // onBlur={props.onBlur}
      />
    )}
    {props.control === 'textarea' && (
      <textarea
        id={props.id}
        rows={props.rows}
        required={props.required}
        value={props.value}
        onChange={(e) => props.onChange(props.id, e.target.value)}
        onClick={props.onClick}
        // onBlur={props.onBlur}
      />
    )}
  </div>
);

export default input;
