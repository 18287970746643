import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import ImgNextGen from './ImgNextGen';
import config from './config';
import { getUser, logoutHandler } from '../services/auth';

const Header = ({ siteTitle }) => (
  <header
    className="top-navigation"
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 1060,
        padding: '1.45rem 1.0875rem',
      }}
    >
      <div className="top-nav-bar-container" style={{ paddingBottom: '0.7rem' }}>
        <div>
          <Link
            to="/"
            style={{
              textDecoration: 'none',
            }}
          >
            <ImgNextGen fallback={`${config.img}logo-transparent.png`} srcWebp={`${config.img}logo-transparent.webp`} className="top-nav-logo" />
            <ImgNextGen fallback={`${config.img}pixopixa-logo-1.png`} srcWebp={`${config.img}pixopixa-logo-1.webp`} className="top-nav-logo2" />
          </Link>
          <div className="main-nav-link" style={{ display: 'inline-block', marginLeft: '3.5rem' }}>Categories</div>
        </div>
        <ul className="top-nav-bar-ul">
          {getUser() ? (
            <li style={{ display: 'inline-block' }}><Link className="main-nav-link" to="/secure/profile">Profile</Link></li>
          ) : null }
          {getUser() ? (
            <li style={{ display: 'inline-block', paddingLeft: '1.5rem' }}>
              <a
                href="/"
                className="main-nav-link"
                onClick={(event) => {
                  event.preventDefault();
                  logoutHandler();
                }}
              >
                Logout
              </a>
            </li>
          )
            : (
              <li style={{ display: 'inline-block', verticalAlign: 'middle' }}><Link className="main-nav-link" to="/secure/login">Login</Link></li>
            )}
          {getUser()
            ? (
              <li
                style={{
                  display: 'inline-block', cursor: 'pointer', marginLeft: '1.5rem', verticalAlign: 'middle',
                }}
                onClick={() => { navigate('/secure/user-settings'); }}
              >
                <img src={localStorage.getItem('profile-url')} width="45" height="45" />
                <br />
                <div style={{ fontSize: '1.1rem', textAlign: 'center' }}>{localStorage.getItem('name')}</div>
              </li>
            )
            : <li style={{ display: 'inline-block', paddingLeft: '1.5rem', verticalAlign: 'middle' }}><Link className="main-nav-link" to="/sign-up">Sign Up</Link></li>}
        </ul>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
